.App {
  padding: 0;
  max-height: 100%;
  min-height: 100vh;
}

.logout {
  font-size: 20px;
}

.ant-layout-content.main-content {
  text-align: left;
  padding: 20px;
  min-height: calc(100vh - 57px - 22px);
}

.ant-card-head-title {
  text-align-last: left;
}
